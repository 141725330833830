import React from "react";
import { classNames } from "./../../../helpers/classNames";



const CommandModule = ({ module, setModule }) => {
  const tabs = [
    {
      name: "Dashboard",
      current: module === "Dashboard",
    },
    {
      name: "Notes",
      current: module === "Notes",
    },

    {
      name: "Financial Story",
      current: module === "Financial Story",
    },
    {
      name: 'Vault',
      current: module === "Vault",
    },
    {
      name: "Emails",
      current: module === "Emails", 
    },
    {
      name: "Meetings",
      current: module === "Meetings",
    },
    {
      name: 'Risk Tolerance',
      current: module === "Risk Tolerance",
    },
    {
      name: "Metrics",
      current: module === "Metrics",
    },
    {
      name: "Settings",
      current: module === "Settings",
    }
  ];
  return (
    <div className="flex">
      <div className="xl:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => setModule(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden xl:block border-b border-gray-200 ">
        <nav className="flex space-x-4  " aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              onClick={() => setModule(tab.name)}
              key={tab.name}
              className={classNames(
                tab.current
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 py-4 px-3 text-md font-medium'
              )}
            >
              <p>{tab.name}</p>
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default CommandModule;
