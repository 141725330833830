import React, { useState, useRef, useEffect } from 'react'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Message from './Message';
import { PencilIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR);

// Separate component for draft items to follow React hooks rules
const DraftItem = ({ id, onSelect, index, handleKeyDown }) => {
  const { data: draft, isLoading: isDraftLoading } = useQuery({
    queryFn: bunmailApi.getFn({
      endpoint: 'get_message',
      params: [id],
    }),
    queryKey: ['email', id],
    enabled: !!id,
  });

  return (
    <div
      id={`draft-item-${index}`}
      onClick={() => onSelect(id)}
      onKeyDown={(e) => handleKeyDown(e, id, index)}
      className="p-3 hover:bg-gray-50 cursor-pointer border-b border-gray-100 flex items-center"
      tabIndex="0"
      role="menuitem"
    >
      <div className="flex-1 truncate">
        {isDraftLoading ? (
          <p className="text-sm text-gray-400">Loading...</p>
        ) : (
          <>
            <p className="text-sm font-medium">{draft?.subject || 'New Message'}</p>
            <p className="text-xs text-gray-500 truncate">
              {draft?.to_email || 'No recipients'}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

const EmailMessages = () => {
  const params = useParams()
  const [showDraftsMenu, setShowDraftsMenu] = useState(false);
  const [draftOrder, setDraftOrder] = useState([]);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const { data: draftIds, isLoading, isError } = useQuery({
    queryFn: bunmailApi.getFn({
      endpoint: 'index_drafts_being_composed',
    }),
    queryKey: ['drafts_being_composed'],
  });

  // Initialize or update draft order when drafts change
  useEffect(() => {
    if (draftIds && draftIds.length > 0) {
      // Only initialize if draftOrder is empty or new drafts arrived
      if (draftOrder.length === 0) {
        // Set initial order to match API order
        setDraftOrder(draftIds.map(draft => draft.id));
      } else {
        // Check for new drafts not in the order yet
        const currentIds = new Set(draftOrder);
        const newDraftIds = draftIds
          .map(draft => draft.id)
          .filter(id => !currentIds.has(id));

        // Add any new drafts to the end of the order
        if (newDraftIds.length > 0) {
          setDraftOrder(prevOrder => [...prevOrder, ...newDraftIds]);
        }

        // Remove any drafts that no longer exist
        const existingIds = new Set(draftIds.map(draft => draft.id));
        const updatedOrder = draftOrder.filter(id => existingIds.has(id));
        if (updatedOrder.length !== draftOrder.length) {
          setDraftOrder(updatedOrder);
        }
      }
    }
  }, [draftIds]);

  const queryClient = useQueryClient()

  const { mutate: createDraft } = useMutation(
    async () => bunmailApi.post({
      endpoint: 'create_draft',
      body: {
        is_being_composed: true,
        to_email: '',
        from_email: '',
        from_name: '',
        household_id: params.household_id,
      },
    }),
    {
      onSuccess: (data) => {
        // invalidate the drafts being composed query
        queryClient.invalidateQueries('drafts_being_composed');
      },
      onError: (error) => {
        // Handle error
        console.error(error);
      },
    }
  )


  // Function to promote a draft to the top of the order
  const promoteDraft = (id) => {
    // Only update the order - move the selected draft to the front
    setDraftOrder(prevOrder => {
      const newOrder = prevOrder.filter(draftId => draftId !== id);
      return [id, ...newOrder];
    });

    // Close the dropdown menu
    setShowDraftsMenu(false);
  };

  // Get visible drafts (first 2 in order)
  const visibleDraftIds = draftOrder.slice(0, 2)
    .map(id => ({ id }))
    .filter(draft => draftIds?.some(d => d.id === draft.id));

  // Get additional drafts (rest of order)
  const additionalDraftIds = draftOrder.slice(2)
    .map(id => ({ id }))
    .filter(draft => draftIds?.some(d => d.id === draft.id));

  const hasAdditionalDrafts = additionalDraftIds.length > 0;

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDraftsMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, buttonRef]);

  // Handle keyboard navigation
  const handleKeyDown = (e, id, index) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      promoteDraft(id);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextElement = document.getElementById(`draft-item-${index + 1}`);
      if (nextElement) nextElement.focus();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevElement = document.getElementById(`draft-item-${index - 1}`);
      if (prevElement) prevElement.focus();
      else buttonRef.current?.focus();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setShowDraftsMenu(false);
      buttonRef.current?.focus();
    }
  };

  return (
    <div className='fixed right-24 bottom-0 z-50 '>
      <div className="flex space-x-5 items-end">
        {visibleDraftIds.map(({ id }) => (
          <div key={id}>
            <Message id={id} />
          </div>
        ))}

        {/* Additional Drafts Menu */}
        {hasAdditionalDrafts && (
          <div className="relative mb-6">
            <button
              ref={buttonRef}
              onClick={() => setShowDraftsMenu(!showDraftsMenu)}
              className="btn-primary-rounded p-2 flex items-center"
              aria-haspopup="true"
              aria-expanded={showDraftsMenu}
            >
              <span className="mr-1">{additionalDraftIds.length}+</span>
              <ChevronUpIcon className={`h-4 w-4 transition-transform ${showDraftsMenu ? '' : 'transform rotate-180'}`} />
            </button>

            {showDraftsMenu && (
              <div
                ref={dropdownRef}
                className="absolute bottom-full mb-2 right-0 w-64 bg-white rounded-lg border border-gray-200 shadow-lg overflow-hidden"
                role="menu"
              >
                <div className="p-2 bg-gray-100 border-b border-gray-200">
                  <h3 className="text-sm font-medium">Additional Drafts</h3>
                </div>
                <div className="max-h-60 overflow-y-auto">
                  {additionalDraftIds.map(({ id }, index) => (
                    <DraftItem
                      key={id}
                      id={id}
                      index={index}
                      onSelect={promoteDraft}
                      handleKeyDown={handleKeyDown}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <button onClick={() => createDraft()} className="btn-gray-rounded mb-6 p-2"><PencilIcon className='h-5 w-5' /></button>
      </div>
    </div>
  )
}

export default EmailMessages