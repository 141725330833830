import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { BellIcon } from "@heroicons/react/outline";
import ApiRequest from "../../api/ApiRequest";
import { API_ROUTES, API_AUTH_TYPES } from "../../api/apiConstants";
import WideBlankModal from "../modals/WideBlankModal";
import BlankModal from "../modals/BlankModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import RemindersList from "./RemindersList";
import ReminderForm from "./ReminderForm";
import useKeyboardShortcut from "../../hooks/useKeyShortcut";

/**
 * A floating button that shows upcoming reminders count and opens a reminder modal
 * when clicked. Allows creating new reminders.
 */
export default function ReminderButton() {
    const [reminderModalOpen, setReminderModalOpen] = useState(false);
    const [reminderFormModalOpen, setReminderFormModalOpen] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null);

    const params = useParams()
    const [household, setHousehold] = useState(null);
    const queryClient = useQueryClient();

    const remindersApi = new ApiRequest(
        API_ROUTES.REMINDERS,
        API_AUTH_TYPES.ADVISOR
    );

    // Create reminder mutation
    const { mutate: createInitialReminder } = useMutation(
        async () => remindersApi.post({
            endpoint: "create",
            body: {
                title: "New Reminder",
                content: { ops: [{ insert: "\n" }] },
                due_date: new Date().toISOString().split('T')[0],
                household_id: params.household_id
            }
        }),
        {
            onSuccess: (data) => {
                // Once we have the created reminder, set it and open modal
                setSelectedReminder(data);
                setReminderFormModalOpen(true);
                queryClient.invalidateQueries(["upcoming-reminders", params.household_id]);
            },
            onError: (error) => {
                console.error("Error creating reminder:", error);
            }
        }
    );

    // Add keyboard shortcut for creating a new reminder (Ctrl+Alt+N)
    useKeyboardShortcut(
        () => {
            createInitialReminder();
        },
        "KeyN",
        true,  // Ctrl key
        false, // Shift key
        true   // Alt key
    );

    // Add keyboard shortcut for opening reminder list (Ctrl+Alt+R)
    useKeyboardShortcut(
        () => {
            setReminderModalOpen(true);
        },
        "KeyR",
        true,  // Ctrl key
        false, // Shift key
        true   // Alt key
    );

    // Fetch upcoming reminders to display count
    const { data: upcomingReminders } = useQuery({
        queryKey: ["upcoming-reminders", params.household_id],
        queryFn: () => {
            const queryParams = { days: 7 };
            console.log('params', params)
            // Only include household ID in query if it exists
            if (params.household_id) {
                queryParams.householdId = params.household_id;
            }

            return remindersApi.get({
                endpoint: "upcoming",
                queryParams: queryParams
            });
        },
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        console.log('invalidating', params.household_id)
        queryClient.invalidateQueries(["upcoming-reminders", params.household_id])
    }, [params.household_id])

    useEffect(() => {
        if (params.household_id && !household) {
            // Need to fetch the complete household data when we only have an ID
            const fetchHousehold = async () => {
                try {
                    // Using the ApiRequest utility
                    const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);
                    const householdData = await householdsApi.get({
                        endpoint: "details_households",
                        params: [params.household_id]
                    });

                    setHousehold(householdData);
                } catch (error) {
                    console.error("Error fetching household data:", error);
                }
            };

            fetchHousehold();
        }
    }, [params.household_id]);


    return (
        <>
            <button
                onClick={() => {
                    setReminderModalOpen(true);
                }}
                className="bg-amber-500 shadow-2xl rounded-full sm:w-16 sm:h-16 w-14 h-14 fixed right-6 bottom-24 hover:brightness-110 z-50"
                title={`${params.household_id
                    ? `View upcoming reminders for ${household?.name || 'this household'}`
                    : 'Your upcoming reminders past due and over the next week for your entire client base'}${upcomingReminders?.length > 0
                        ? ` | Badge shows count of upcoming reminders for ${params.household_id ? (household?.name || 'this household') : 'your entire client base'}`
                        : ''
                    } | Shortcut: Ctrl+Alt+R | Create New Reminder: Ctrl+Alt+N`}
            >
                <BellIcon className="w-7 h-7 sm:h-8 sm:w-8 text-white my-auto mx-auto" />
                {upcomingReminders?.length > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/3 -translate-y-1/3">
                        {upcomingReminders.length}
                    </span>
                )}
            </button>

            {/* Reminder List Modal */}
            <WideBlankModal
                open={reminderModalOpen}
                setOpen={setReminderModalOpen}
            >
                <RemindersList householdId={params.household_id} householdName={household?.name} defaultDateRange={'next7Days'} quickAdd={true} />
            </WideBlankModal>

            {/* Reminder Form Modal - for hotkey created reminders */}
            <BlankModal
                open={reminderFormModalOpen}
                setOpen={setReminderFormModalOpen}
            >
                <ReminderForm
                    reminder={selectedReminder}
                    householdId={params.household_id}
                    setIsOpen={setReminderFormModalOpen}
                />
            </BlankModal>
        </>
    );
}
