import React, { useState, useEffect } from "react";
import QuillTextEditor from "../textEditor/Quill-Text-Editor";
import HouseholdSearch from "../comboboxes/HouseholdSearch";
import { API_ROUTES, API_AUTH_TYPES, ApiRequest } from "../../api";
import { useMutation, useQueryClient } from "react-query";

export default function ReminderForm({
    reminder,
    householdId,
    setIsOpen,
}) {
    const [content, setContent] = useState(reminder?.content || null);
    const [dueDate, setDueDate] = useState(
        reminder?.due_date ? new Date(reminder.due_date).toISOString().split('T')[0] : ""
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dueDateEnabled, setDueDateEnabled] = useState(!!reminder?.due_date);
    const [assignedTo, setAssignedTo] = useState(reminder?.assigned_to || "me");
    const [household, setHousehold] = useState(reminder?.household || null);
    const [errors, setErrors] = useState({});

    const queryClient = useQueryClient();
    const remindersApi = new ApiRequest(
        API_ROUTES.REMINDERS,
        API_AUTH_TYPES.ADVISOR
    );

    // Log when household changes
    useEffect(() => {
        console.log("Household state changed:", household);
    }, [household]);

    const { mutate: updateReminder, isLoading: isUpdating } = useMutation(
        (reminderData) => {
            console.log("Making API request to update reminder:", {
                endpoint: "update",
                params: [reminder.id],
                body: reminderData
            });

            return remindersApi.patch({
                endpoint: "update",
                params: [reminder.id],
                body: reminderData
            });
        },
        {
            onSuccess: (data) => {
                console.log("Reminder updated successfully:", data);
                queryClient.invalidateQueries(["reminders"]);
                queryClient.invalidateQueries("upcoming-reminders");
                queryClient.invalidateQueries("overdue-reminders");

                queryClient.invalidateQueries(["reminder", reminder.id]);
                queryClient.invalidateQueries(["upcoming-reminders", householdId]);
                setIsOpen(false);
            },
            onError: (error) => {
                console.error("Error updating reminder:", error);
            }
        }
    );


    useEffect(() => {
        if (reminder) {
            const hasDueDate = !!reminder.due_date;
            setDueDate(hasDueDate ? new Date(reminder.due_date).toISOString().split('T')[0] : "");
            setDueDateEnabled(hasDueDate);
            setAssignedTo(reminder.assigned_to || "me");
            setContent(reminder.content);
            setHousehold(reminder.household || null);
        }
    }, [reminder]);

    useEffect(() => {
        if (householdId && !household) {
            // Need to fetch the complete household data when we only have an ID
            const fetchHousehold = async () => {
                try {
                    // Using the ApiRequest utility
                    const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);
                    const householdData = await householdsApi.get({
                        endpoint: "details_households",
                        params: [householdId]
                    });

                    console.log("Fetched household data:", householdData);
                    setHousehold(householdData);
                } catch (error) {
                    console.error("Error fetching household data:", error);
                }
            };

            fetchHousehold();
        }
    }, [householdId, household]);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Validation
        const newErrors = {};
        if (dueDateEnabled && !dueDate) newErrors.dueDate = "Due date is required when enabled";
        if (!content || !content.ops || content.ops.length === 0) {
            newErrors.content = "Content is required";
            setErrors(newErrors);
            return;
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const reminderData = {
            due_date: dueDateEnabled ? dueDate : null,
            assigned_to: assignedTo,
            content,
            household_id: household?.household_id || householdId || null
        };

        console.log("Sending reminder data:", reminderData);
        console.log("Household selected:", household);

        setIsSubmitting(true);
        updateReminder(reminderData);
    };

    const toggleDueDate = () => {
        setDueDateEnabled(!dueDateEnabled);
    };

    return (
        <form onSubmit={handleFormSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label htmlFor="household" className="block text-sm font-medium text-gray-700 mb-1">
                        Household
                    </label>
                    <HouseholdSearch
                        selector={true}
                        label="Household"
                        household={household}
                        setHousehold={setHousehold}
                    />
                </div>

                <div>
                    <div className="flex items-center justify-between mb-1">
                        <label htmlFor="due_date" className="block text-sm font-medium text-gray-700">
                            Due Date
                        </label>
                        <div className="flex items-center">
                            <label className="inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={dueDateEnabled}
                                    onChange={toggleDueDate}
                                    className="sr-only peer"
                                />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                            </label>
                        </div>
                    </div>

                    {dueDateEnabled ? (
                        <input
                            type="date"
                            id="due_date"
                            value={dueDate}
                            onChange={(e) => setDueDate(e.target.value)}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${errors.dueDate ? "border-red-500" : ""
                                }`}
                        />
                    ) : (
                        <div className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-50 py-2 px-3 text-gray-500 text-sm">
                            No due date
                        </div>
                    )}

                    {errors.dueDate && (
                        <p className="mt-1 text-sm text-red-600">{errors.dueDate}</p>
                    )}
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Assigned To
                </label>
                <div className="flex space-x-4">
                    <button
                        type="button"
                        onClick={() => setAssignedTo("me")}
                        className={`flex-1 py-2 px-4 rounded-md border ${assignedTo !== "parabot"
                            ? "bg-blue-600 text-white border-blue-600"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                            }`}
                    >
                        Me
                    </button>
                    <button
                        type="button"
                        onClick={() => setAssignedTo("parabot")}
                        className={`flex-1 py-2 px-4 rounded-md border ${assignedTo === "parabot"
                            ? "bg-blue-600 text-white border-blue-600"
                            : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
                            }`}
                    >
                        Parabot
                    </button>
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Content
                </label>
                <QuillTextEditor
                    placeholder="Enter reminder details..."
                    options={{
                        route: "reminders",
                        auth: "advisor",
                        is_draft: false,
                        search_params: {
                            document_id: reminder?.id
                        }
                    }}
                    queryKey={["reminder-editor", reminder?.id || "new"]}
                    handleChangeSideEffect={(newContent) => setContent(newContent)}
                />
                {errors.content && (
                    <p className="mt-1 text-sm text-red-600">{errors.content}</p>
                )}
            </div>

            <div className="flex justify-end pt-4">
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary btn-md"
                >
                    {isSubmitting ? "Saving..." : reminder ? "Update Reminder" : "Create Reminder"}
                </button>
            </div>
        </form>
    );
} 