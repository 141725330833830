import React from "react";
import {
    ClockIcon,
    CheckCircleIcon,
    ArchiveIcon,
    RefreshIcon,
    PencilAltIcon,
    DocumentTextIcon
} from "@heroicons/react/outline";

export default function ReminderCard({ reminder, onComplete, onArchive, onReactivate, onEdit, onSaveAsNote, currentDateString }) {
    const { id, title, content, due_date, status, assigned_to, household_id, household_name } = reminder;
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0];

    const getDueStatus = () => {
        // Extract date parts from the ISO string
        const dateStr = due_date.split('T')[0];
        const [dueYear, dueMonth, dueDay] = dateStr.split('-').map(num => parseInt(num, 10));

        // Get current date parts from the provided string
        const [currentYear, currentMonth, currentDay] = todayStr.split('-').map(num => parseInt(num, 10));

        // Create comparison values (YYYYMMDD format for easy comparison)
        const dueValue = dueYear * 10000 + dueMonth * 100 + dueDay;
        const todayValue = currentYear * 10000 + currentMonth * 100 + currentDay;
        const tomorrowValue = getTomorrowValue(currentYear, currentMonth, currentDay);

        // The formatted actual date (always the same)
        const formattedDate = formatDate(dueYear, dueMonth, dueDay);

        if (dueValue < todayValue) {
            return {
                pillText: "Overdue",
                dateText: formattedDate,
                pillClass: "bg-red-100 text-red-800"
            };
        } else if (dueValue === todayValue) {
            return {
                pillText: "Today",
                dateText: formattedDate,
                pillClass: "bg-amber-100 text-amber-800"
            };
        } else if (dueValue === tomorrowValue) {
            return {
                pillText: "Tomorrow",
                dateText: formattedDate,
                pillClass: "bg-blue-100 text-blue-800"
            };
        } else {
            // Calculate difference in days, months, years
            const yearDiff = dueYear - currentYear;
            const monthDiff = dueMonth - currentMonth;
            const dayDiff = dueDay - currentDay;

            // Calculate approximate days difference
            let totalDaysDiff = 0;

            if (yearDiff === 0 && monthDiff === 0) {
                // Same month, just day difference
                totalDaysDiff = dayDiff;
            } else {
                // Different month or year - calculate approximate days
                // First, add days remaining in current month
                totalDaysDiff = getDaysInMonth(currentYear, currentMonth) - currentDay;

                // Add days in full months between
                let tempYear = currentYear;
                let tempMonth = currentMonth + 1;

                // Adjust if month overflows
                if (tempMonth > 12) {
                    tempMonth = 1;
                    tempYear++;
                }

                // Add days for each month until we reach the month before the due date
                while (tempYear < dueYear || (tempYear === dueYear && tempMonth < dueMonth)) {
                    totalDaysDiff += getDaysInMonth(tempYear, tempMonth);
                    tempMonth++;

                    if (tempMonth > 12) {
                        tempMonth = 1;
                        tempYear++;
                    }
                }

                // Add days in the final month
                totalDaysDiff += dueDay;
            }
            console.log("totalDaysDiff", totalDaysDiff);

            // Decide what text to display based on the difference
            if (totalDaysDiff <= 7) {
                // Show "in X days" for differences up to a week
                return {
                    pillText: `${totalDaysDiff} day${totalDaysDiff !== 1 ? 's' : ''}`,
                    dateText: formattedDate,
                    pillClass: "bg-blue-100 text-blue-800"
                };
            } else if (totalDaysDiff <= 30) {
                // Show "in X weeks" for differences between 1-4 weeks
                const weeks = Math.ceil(totalDaysDiff / 7);
                return {
                    pillText: `${weeks} week${weeks !== 1 ? 's' : ''}`,
                    dateText: formattedDate,
                    pillClass: "bg-blue-100 text-blue-800"
                };
            } else if (yearDiff === 0) {
                // Same year but more than a month away - show "in X months"
                let monthsAway = monthDiff;
                if (dayDiff < 0) {
                    // If the day of month is earlier, round down
                    monthsAway--;
                }
                // Adjust for negative month diff (which shouldn't happen in normal cases)
                monthsAway = Math.max(1, monthsAway);
                return {
                    pillText: `${monthsAway} month${monthsAway !== 1 ? 's' : ''}`,
                    dateText: formattedDate,
                    pillClass: "bg-gray-100 text-gray-800"
                };
            } else {
                // More than a year away
                return {
                    pillText: `${yearDiff} year${yearDiff !== 1 ? 's' : ''}`,
                    dateText: formattedDate,
                    pillClass: "bg-gray-100 text-gray-800"
                };
            }
        }
    };

    // Helper function to get tomorrow's value
    const getTomorrowValue = (year, month, day) => {
        day++;

        // Handle month overflow
        const daysInMonth = getDaysInMonth(year, month);
        if (day > daysInMonth) {
            day = 1;
            month++;

            // Handle year overflow
            if (month > 12) {
                month = 1;
                year++;
            }
        }

        return year * 10000 + month * 100 + day;
    };

    // Helper to get days in month
    const getDaysInMonth = (year, month) => {
        const daysPerMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Check for leap year
        if (month === 2 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
            return 29;
        }

        return daysPerMonth[month];
    };

    // Simple date formatter
    const formatDate = (year, month, day) => {
        const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${monthNames[month]} ${day}, ${year}`;
    };

    const dueStatus = getDueStatus();

    // Function to extract plain text from Quill content
    const getContentPreview = () => {
        if (!content || !content.ops) return "";
        return content.ops
            .map(op => typeof op.insert === 'string' ? op.insert : '')
            .join('')
            .trim()
            .substring(0, 120) + (content.ops.join('').length > 120 ? '...' : '');
    };

    // Handle card click to edit reminder
    const handleCardClick = (e) => {
        // Only trigger if not clicking on an action button
        if (!e.target.closest('button')) {
            onEdit(reminder);
        }
    };

    return (
        <div
            className="bg-white rounded-lg border border-gray-200 text-left p-4 hover:shadow-md transition-shadow cursor-pointer"
            onClick={handleCardClick}
        >
            <div className="flex justify-between items-start">
                <div className="flex-1 min-w-0">
                    <h3 className="text-lg font-semibold text-gray-900 truncate">{title}</h3>
                    <div className="flex items-center mt-1 space-x-2">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${dueStatus.pillClass}`}>
                            <ClockIcon className="h-3 w-3 mr-1" />
                            {dueStatus.pillText}
                        </span>
                        <span className="text-sm text-gray-400">{dueStatus.dateText}</span>
                    </div>
                    {household_name && (
                        <p className="text-sm text-gray-600 mt-1 flex items-center">
                            <span className="font-medium">Household: {household_name}</span>
                        </p>
                    )}
                    {assigned_to && (
                        <p className="text-sm text-gray-600 mt-1 flex items-center">
                            {assigned_to === "parabot" && (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                    <span className="font-medium text-purple-600">Assigned to Parabot</span>
                                </>
                            )}
                        </p>
                    )}
                </div>
                <div className="flex space-x-2 ml-4">
                    {status === 'active' && (
                        <>
                            <button
                                onClick={() => onComplete(id)}
                                className="p-1 rounded-full hover:bg-green-50 text-green-500"
                                title="Mark as Complete"
                            >
                                <CheckCircleIcon className="h-5 w-5" />
                            </button>
                            <button
                                onClick={() => onArchive(id)}
                                className="p-1 rounded-full hover:bg-gray-100 text-gray-500"
                                title="Archive"
                            >
                                <ArchiveIcon className="h-5 w-5" />
                            </button>
                            {household_id && <button
                                onClick={() => onSaveAsNote(reminder)}
                                className="p-1 rounded-full hover:bg-purple-50 text-purple-500"
                                title="Save as Note"
                            >
                                <DocumentTextIcon className="h-5 w-5" />
                            </button>}
                        </>
                    )}
                    {status !== 'active' && (
                        <button
                            onClick={() => onReactivate(id)}
                            className="p-1 rounded-full hover:bg-blue-50 text-blue-500"
                            title="Reactivate"
                        >
                            <RefreshIcon className="h-5 w-5" />
                        </button>
                    )}
                    <button
                        onClick={() => onEdit(reminder)}
                        className="p-1 rounded-full hover:bg-blue-50 text-blue-500"
                        title="Edit"
                    >
                        <PencilAltIcon className="h-5 w-5" />
                    </button>
                </div>
            </div>
            <div className="mt-2">
                <p className="text-sm text-gray-600 line-clamp-2">{getContentPreview()}</p>
            </div>
        </div>
    );
} 