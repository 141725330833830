import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReminderCard from "./ReminderCard";
import ApiRequest from "../../api/ApiRequest";
import { API_ROUTES, API_AUTH_TYPES } from "../../api/apiConstants";
import { SearchIcon, PlusIcon, CheckCircleIcon, ChevronDownIcon, SwitchHorizontalIcon, CalendarIcon } from "@heroicons/react/outline";
import HouseholdSearch from "../comboboxes/HouseholdSearch";
import ReminderForm from "./ReminderForm";
import BlankModal from "../modals/BlankModal";
import { classNames } from "../misc/styling";

// Define date range options
const DATE_RANGE_OPTIONS = [
    { id: "all", label: "All Dates" },
    { id: "pastDue", label: "Past Due" },
    { id: "today", label: "Today" },
    { id: "next7Days", label: "Next 7 Days" },
    { id: "next30Days", label: "Next 30 Days" },
    { id: "noDate", label: "No Due Date" },
];

// Define household filter options
const HOUSEHOLD_FILTER_OPTIONS = [
    { id: "all", label: "All Reminders" },
    { id: "household", label: "With Household" },
    { id: "noHousehold", label: "No Household" },
];

export default function RemindersList({
    householdId,
    householdName,
    defaultDateRange = "all",
    listHeight = "max-h-[500px]"
}) {
    const [filter, setFilter] = useState("active");
    const [dateRangeFilter, setDateRangeFilter] = useState(defaultDateRange);
    const [dateRangeDropdownOpen, setDateRangeDropdownOpen] = useState(false);
    const [householdFilter, setHouseholdFilter] = useState("all");
    const [householdFilterDropdownOpen, setHouseholdFilterDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [notification, setNotification] = useState(null);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [selectedHouseholdId, setSelectedHouseholdId] = useState(householdId || null);
    const [household, setHousehold] = useState(
        householdId && householdName ? { id: householdId, name: householdName } : null
    );
    const [editReminderModalOpen, setEditReminderModalOpen] = useState(false);
    const [showAllReminders, setShowAllReminders] = useState(householdId ? false : true);
    const [selectedReminder, setSelectedReminder] = useState(null);

    const statusDropdownRef = useRef(null);
    const dateRangeDropdownRef = useRef(null);
    const householdFilterDropdownRef = useRef(null);
    const isFirstRender = useRef(true);

    const queryClient = useQueryClient();
    const remindersApi = new ApiRequest(
        API_ROUTES.REMINDERS,
        API_AUTH_TYPES.ADVISOR
    );

    const { mutate: createInitialReminder, isLoading: isCreating } = useMutation(
        async () => remindersApi.post({
            endpoint: "create",
            body: {
                title: "New Reminder",
                content: { ops: [{ insert: "\n" }] },
                due_date: new Date().toISOString().split('T')[0],
                household_id: householdId
            }
        }),
        {
            onSuccess: (data) => {
                // Once we have the created reminder, set it and open modal
                setSelectedReminder(data);
                setEditReminderModalOpen(true);
            },
            onError: (error) => {
                console.error("Error creating reminder:", error);

            }
        }
    );

    const onCreateReminder = () => {
        // Create the reminder first via API call
        createInitialReminder();
    };

    const onEditReminder = (reminder) => {
        setSelectedReminder(reminder);
        setEditReminderModalOpen(true);
    };

    // Initialize household data if householdId is provided - only once on first render
    useEffect(() => {
        if (isFirstRender.current && householdId && householdName) {
            setHousehold({
                id: householdId,
                name: householdName
            });
            setSelectedHouseholdId(householdId);
            isFirstRender.current = false;
        }
    }, [householdId, householdName]);

    // Clear notification after timeout
    useEffect(() => {
        if (notification) {
            const timer = setTimeout(() => {
                setNotification(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [notification]);

    // Handle clicks outside dropdowns to close them
    useEffect(() => {
        function handleClickOutside(event) {
            if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
                setStatusDropdownOpen(false);
            }
            if (dateRangeDropdownRef.current && !dateRangeDropdownRef.current.contains(event.target)) {
                setDateRangeDropdownOpen(false);
            }
            if (householdFilterDropdownRef.current && !householdFilterDropdownRef.current.contains(event.target)) {
                setHouseholdFilterDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const queryKey = [
        "reminders",
        showAllReminders ? "all" : (selectedHouseholdId ? selectedHouseholdId : "advisor"),
        filter
    ];

    // Debug log
    console.log("Query params:", {
        selectedHouseholdId,
        showAllReminders,
        queryKey,
        endpoint: selectedHouseholdId && !showAllReminders ? "index_household" : "index_advisor"
    });

    const { data: reminders, isLoading } = useQuery({
        queryFn: () => {
            console.log("Inside queryFn:", { selectedHouseholdId, showAllReminders });

            if (selectedHouseholdId && !showAllReminders) {
                console.log("Using index_household endpoint");
                return remindersApi.get({
                    endpoint: "index_household",
                    params: [selectedHouseholdId],
                    queryParams: { status: filter }
                });
            } else {
                console.log("Using index_advisor endpoint");
                return remindersApi.get({
                    endpoint: "index_advisor",
                    queryParams: { status: filter }
                });
            }
        },
        queryKey,
        refetchOnWindowFocus: false,
        enabled: selectedHouseholdId !== undefined
    });

    const { mutate: completeReminder } = useMutation(
        (reminderId) => remindersApi.patch({
            endpoint: "complete",
            params: [reminderId]
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
                queryClient.invalidateQueries("upcoming-reminders");
                queryClient.invalidateQueries("overdue-reminders");
            }
        }
    );

    const { mutate: archiveReminder } = useMutation(
        (reminderId) => remindersApi.patch({
            endpoint: "archive",
            params: [reminderId]
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
            }
        }
    );



    const { mutate: reactivateReminder } = useMutation(
        (reminderId) => remindersApi.patch({
            endpoint: "reactivate",
            params: [reminderId]
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
                queryClient.invalidateQueries("upcoming-reminders");
                queryClient.invalidateQueries("overdue-reminders");
            }
        }
    );

    const { mutate: saveAsNote } = useMutation(
        (reminder) => remindersApi.post({
            endpoint: "save_as_note",
            params: [reminder.id],
            body: { householdId: reminder.household_id }
        }),
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(["household-notes", householdId]);
                // Show success notification
                setNotification({
                    type: 'success',
                    message: `Reminder "${variables.title || 'Reminder'}" saved as note successfully.`,
                    householdName: variables.household_name
                });
            },
            onError: () => {
                setNotification({
                    type: 'error',
                    message: 'Failed to save reminder as note. Please try again.'
                });
            }
        }
    );

    // Determine the label text for date range filter
    const getDateRangeLabel = () => {
        const option = DATE_RANGE_OPTIONS.find(opt => opt.id === dateRangeFilter);
        return `Date: ${option?.label || 'All Dates'}`;
    };

    // Determine the label text for household filter
    const getHouseholdFilterLabel = () => {
        const option = HOUSEHOLD_FILTER_OPTIONS.find(opt => opt.id === householdFilter);
        return `Household: ${option?.label || 'All Reminders'}`;
    };

    // Function to convert date string to numeric value (YYYYMMDD)
    const getDateValue = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
        return year * 10000 + month * 100 + day;
    };

    // Get days in a month (accounting for leap years)
    const getDaysInMonth = (year, month) => {
        const daysPerMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Check for leap year
        if (month === 2 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
            return 29;
        }

        return daysPerMonth[month];
    };

    // Function to get a future date value
    const getFutureDateValue = (year, month, day, daysToAdd) => {
        let newDay = day + daysToAdd;
        let newMonth = month;
        let newYear = year;

        // Handle day overflow through months and years
        while (true) {
            const daysInMonth = getDaysInMonth(newYear, newMonth);
            if (newDay <= daysInMonth) break;

            newDay -= daysInMonth;
            newMonth++;

            if (newMonth > 12) {
                newMonth = 1;
                newYear++;
            }
        }

        return newYear * 10000 + newMonth * 100 + newDay;
    };

    // Get current date string in YYYY-MM-DD format without using Date object
    // This could be replaced with server time or other external time source
    const getCurrentDate = () => {
        // We're still using Date here, but this function could be replaced with
        // a server-side implementation that provides the current date string
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const filteredReminders = reminders?.filter(reminder => {
        if (!searchTerm && dateRangeFilter === "all" && householdFilter === "all") return true;

        let matchesSearch = true;
        let matchesDateRange = true;
        let matchesHouseholdFilter = true;

        // Apply search filter
        if (searchTerm) {
            const searchTermLower = searchTerm.toLowerCase();
            matchesSearch = reminder.title?.toLowerCase().includes(searchTermLower) ||
                (reminder.content?.ops?.some(op =>
                    typeof op.insert === "string" && op.insert.toLowerCase().includes(searchTermLower)
                ));
        }

        // Apply date range filter
        if (dateRangeFilter !== "all") {
            if (!reminder.due_date) {
                matchesDateRange = dateRangeFilter === "noDate";
            } else {
                const dateString = reminder.due_date.split('T')[0];

                // Get current date components
                const todayStr = getCurrentDate();
                const [currentYear, currentMonth, currentDay] = todayStr.split('-').map(num => parseInt(num, 10));

                // Convert dates to numeric values for comparison
                const dueValue = getDateValue(dateString);
                const todayValue = getDateValue(todayStr);
                const nextWeekValue = getFutureDateValue(currentYear, currentMonth, currentDay, 7);
                const nextMonthValue = getFutureDateValue(currentYear, currentMonth, currentDay, 30);

                switch (dateRangeFilter) {
                    case "pastDue":
                        matchesDateRange = dueValue < todayValue;
                        break;
                    case "today":
                        matchesDateRange = dueValue === todayValue;
                        break;
                    case "next7Days":
                        // Include overdue tasks + tasks due within next 7 days
                        matchesDateRange = dueValue <= nextWeekValue;
                        break;
                    case "next30Days":
                        // Include overdue tasks + tasks due within next 30 days
                        matchesDateRange = dueValue <= nextMonthValue;
                        break;
                    case "noDate":
                        matchesDateRange = false; // Already handled above
                        break;
                    default:
                        matchesDateRange = true;
                }
            }
        }

        // Apply household filter
        if (householdFilter !== "all") {
            switch (householdFilter) {
                case "household":
                    matchesHouseholdFilter = reminder.household_id !== null;
                    break;
                case "noHousehold":
                    matchesHouseholdFilter = reminder.household_id === null;
                    break;
                default:
                    matchesHouseholdFilter = true;
            }
        }

        return matchesSearch && matchesDateRange && matchesHouseholdFilter;
    });

    // Determine the label text for each filter type
    const getStatusLabel = () => {
        switch (filter) {
            case "active": return "Status: Active";
            case "completed": return "Status: Completed";
            case "archived": return "Status: Archived";
            default: return "Status";
        }
    };

    const handleHouseholdChange = (newHousehold) => {
        // Only update if the household actually changed
        if (newHousehold?.household_id !== selectedHouseholdId) {
            console.log("Household changed:", {
                from: selectedHouseholdId,
                to: newHousehold?.household_id,
                newHousehold
            });

            setHousehold(newHousehold);
            setSelectedHouseholdId(newHousehold?.household_id || null);
            setFilter("active"); // Reset to active filter when changing households
            setShowAllReminders(false); // Reset to show only household reminders

            // Force refetch by invalidating the query
            if (newHousehold?.household_id) {
                queryClient.invalidateQueries(["reminders", newHousehold.household_id, "active"]);
            } else {
                queryClient.invalidateQueries(["reminders"]);
            }
        }
    };

    const toggleShowAllReminders = () => {
        setShowAllReminders(prev => !prev);
    };

    return (
        <>

            <BlankModal
                open={editReminderModalOpen}
                setOpen={setEditReminderModalOpen}
            >
                <ReminderForm
                    reminder={selectedReminder}
                    householdId={householdId}
                    setIsOpen={setEditReminderModalOpen}
                />
            </BlankModal>
            {householdId && (
                <p className="text-sm mb-1 text-gray-500 text-left">
                    {householdName}
                </p>
            )}
            <div className="space-y-4 relative">

                {/* Success/Error notification */}
                {notification && (
                    <div
                        className={`absolute top-0 right-0 z-50 p-4 rounded-md shadow-lg transform transition-transform duration-300 ${notification.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                            }`}
                    >
                        <div className="flex items-center">
                            {notification.type === 'success' && (
                                <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" />
                            )}
                            <div>
                                <p className="font-medium">{notification.message}</p>
                                {notification.householdName && (
                                    <p className="text-sm mt-1">Household: {notification.householdName}</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {/* If householdId is provided, show count in a separate row */}
                {householdId && !isLoading && filteredReminders && (
                    <div className="mb-4 flex justify-end items-center gap-2">
                        <div className="text-[10px] bg-gray-100 py-1 px-2 rounded text-gray-600 flex gap-2">
                            <span><kbd className="px-0.5 border-b border-gray-400">Ctrl+Alt+R</kbd> Open list</span>
                            <span><kbd className="px-0.5 border-b border-gray-400">Ctrl+Alt+N</kbd> New reminder</span>
                        </div>
                        <div className="bg-gray-100 text-gray-700 px-3 py-1.5 rounded-md text-sm font-medium">
                            {filteredReminders.length} reminder{filteredReminders.length !== 1 ? 's' : ''}
                        </div>
                    </div>
                )}

                {/* Household selector - only show if no fixed householdId is provided */}
                {!householdId && (
                    <div className="mb-4 flex flex-wrap items-center justify-between">
                        <div className="flex flex-wrap items-center gap-4">
                            <div className="w-64">
                                <HouseholdSearch
                                    selector={true}
                                    label="Household"
                                    household={household}
                                    setHousehold={handleHouseholdChange}
                                />
                            </div>

                            <button
                                onClick={toggleShowAllReminders}
                                className={`inline-flex items-center px-3 py-2 border text-sm font-medium rounded-md ${showAllReminders
                                    ? "bg-indigo-100 text-indigo-800 border-indigo-300"
                                    : "bg-gray-100 text-gray-800 border-gray-300"
                                    }`}
                            >
                                <SwitchHorizontalIcon className="h-4 w-4 mr-1.5" />
                                {showAllReminders ? "Showing All Reminders" : "Showing Household Reminders"}
                            </button>


                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-[10px] bg-gray-100 py-1 px-2 rounded text-gray-600  flex flex-col items-start">
                                <span><kbd className="px-0.5 border-b border-gray-400">Ctrl+Alt+R</kbd> Open list</span>
                                <span><kbd className="px-0.5 border-b border-gray-400">Ctrl+Alt+N</kbd> New reminder</span>
                            </div>
                            {/* Reminder count */}
                            {!isLoading && filteredReminders && (
                                <div className="bg-gray-100 text-gray-700 px-3 py-1.5 rounded-md text-sm font-medium">
                                    {filteredReminders.length} reminder{filteredReminders.length !== 1 ? 's' : ''}
                                </div>
                            )}
                        </div></div>
                )}

                <div className="flex flex-wrap gap-3 items-center">
                    {/* Status filter dropdown */}
                    <div className="relative" ref={statusDropdownRef}>
                        <button
                            className={`px-4 py-2 text-sm font-medium rounded-md flex items-center ${filter === "active" ? "bg-blue-100 text-blue-800" :
                                filter === "completed" ? "bg-green-100 text-green-800" :
                                    "bg-gray-200 h-full text-gray-800"
                                }`}
                            onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                        >
                            {getStatusLabel()}
                            <ChevronDownIcon className="h-4 w-4 ml-1.5" />
                        </button>

                        {statusDropdownOpen && (
                            <div className="absolute z-10 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical">
                                    <button
                                        className={`block px-4 py-2 text-sm w-full text-left ${filter === "active" ? "bg-blue-50 text-blue-800" : "text-gray-700 hover:bg-gray-100"}`}
                                        onClick={() => {
                                            setFilter("active");
                                            setStatusDropdownOpen(false);
                                        }}
                                    >
                                        Active
                                    </button>
                                    <button
                                        className={`block px-4 py-2 text-sm w-full text-left ${filter === "completed" ? "bg-green-50 text-green-800" : "text-gray-700 hover:bg-gray-100"}`}
                                        onClick={() => {
                                            setFilter("completed");
                                            setStatusDropdownOpen(false);
                                        }}
                                    >
                                        Completed
                                    </button>
                                    <button
                                        className={`block px-4 py-2 text-sm w-full text-left ${filter === "archived" ? "bg-gray-100 text-gray-800" : "text-gray-700 hover:bg-gray-100"}`}
                                        onClick={() => {
                                            setFilter("archived");
                                            setStatusDropdownOpen(false);
                                        }}
                                    >
                                        Archived
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Date range filter dropdown */}
                    <div className="relative" ref={dateRangeDropdownRef}>
                        <button
                            className={`px-4 py-2 text-sm font-medium rounded-md flex items-center ${dateRangeFilter !== "all" ? "bg-indigo-100 text-indigo-800" : "bg-gray-100 text-gray-600"}`}
                            onClick={() => setDateRangeDropdownOpen(!dateRangeDropdownOpen)}
                        >
                            <CalendarIcon className="h-4 w-4 mr-1.5" />
                            {getDateRangeLabel()}
                            <ChevronDownIcon className="h-4 w-4 ml-1.5" />
                        </button>

                        {dateRangeDropdownOpen && (
                            <div className="absolute z-10 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical">
                                    {DATE_RANGE_OPTIONS.map(option => (
                                        <button
                                            key={option.id}
                                            className={`block px-4 py-2 text-sm w-full text-left ${dateRangeFilter === option.id ? "bg-indigo-50 text-indigo-800" : "text-gray-700 hover:bg-gray-100"}`}
                                            onClick={() => {
                                                setDateRangeFilter(option.id);
                                                setDateRangeDropdownOpen(false);
                                            }}
                                        >
                                            {option.label}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Household filter dropdown */}
                    <div className="relative" ref={householdFilterDropdownRef}>
                        <button
                            className={`px-4 py-2 text-sm font-medium rounded-md flex items-center ${householdFilter !== "all" ? "bg-purple-100 text-purple-800" : "bg-gray-100 text-gray-600"}`}
                            onClick={() => setHouseholdFilterDropdownOpen(!householdFilterDropdownOpen)}
                        >
                            {getHouseholdFilterLabel()}
                            <ChevronDownIcon className="h-4 w-4 ml-1.5" />
                        </button>

                        {householdFilterDropdownOpen && (
                            <div className="absolute z-10 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical">
                                    {HOUSEHOLD_FILTER_OPTIONS.map(option => (
                                        <button
                                            key={option.id}
                                            className={`block px-4 py-2 text-sm w-full text-left ${householdFilter === option.id ? "bg-purple-50 text-purple-800" : "text-gray-700 hover:bg-gray-100"}`}
                                            onClick={() => {
                                                setHouseholdFilter(option.id);
                                                setHouseholdFilterDropdownOpen(false);
                                            }}
                                        >
                                            {option.label}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Search box */}
                    <div className="relative flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <SearchIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Search reminders..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    {/* Create reminder button */}
                    {onCreateReminder && (
                        <button
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 whitespace-nowrap"
                            onClick={onCreateReminder}
                        >
                            <PlusIcon className="h-5 w-5 mr-1" />
                            New
                        </button>
                    )}
                </div>

                {isLoading ? (
                    <div className="flex justify-center py-8">
                        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
                    </div>
                ) : filteredReminders?.length > 0 ? (
                    <div className={classNames("space-y-4 pr-1 overflow-y-auto", listHeight)}>
                        {filteredReminders.map((reminder) => (
                            <ReminderCard
                                key={reminder.id}
                                reminder={reminder}
                                onComplete={completeReminder}
                                onArchive={archiveReminder}
                                onReactivate={reactivateReminder}
                                onEdit={() => onEditReminder(reminder)}
                                onSaveAsNote={saveAsNote}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="text-center py-8 bg-gray-50 rounded-lg">
                        <p className="text-gray-500">
                            {searchTerm
                                ? "No reminders match your search"
                                : filter === "active"
                                    ? "No active reminders found"
                                    : filter === "completed"
                                        ? "No completed reminders found"
                                        : "No archived reminders found"}
                        </p>
                    </div>
                )}

            </div>
        </>
    );
} 